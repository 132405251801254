import classNames from 'classnames';

export interface LinkButtonProps extends React.AnchorHTMLAttributes<HTMLElement> {
  type?: 'transparent' | 'supernova';
}

export const LinkButton = ({
  children,
  className,
  type = 'transparent',
  ...rest
}: LinkButtonProps) => {
  const rel = (typeof rest.rel !== 'undefined' && rest.rel) || (rest.target === '_blank' && 'noreferrer') || undefined;

  return (
    <a
      className={classNames(
        'flex items-center justify-center transition-none rounded-full font-bold px-30 py-10 border-1 text-black text-center',
        type === 'transparent' && 'bg-transparent hover:border-supernova hover:text-black',
        type === 'supernova' && 'bg-supernova hover:text-supernova hover:bg-transparent',
        className,
      )}
      rel={rel}
      {...rest}
    >
      {children}
    </a>
  );
};

export default LinkButton;
