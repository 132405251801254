import React from "react";

const LandingPage = React.lazy(() => import('../pages/LandingPage'));
const Blog = React.lazy(() => import('../pages/Blog'));
const BlogContent = React.lazy(() => import('../pages/BlogContent'));
const ServiceArea = React.lazy(() => import('../pages/ServiceArea'));
const Promotion = React.lazy(() => import('../pages/Promotion'));
const PricingPage = React.lazy(() => import('../pages/Pricing'));
const AboutPage = React.lazy(() => import('../pages/AboutPage'));
const TermsServicePage = React.lazy(() => import('../pages/TermsServicePage'));
const JoinWaitListPage = React.lazy(() => import('../pages/WaitListPage'));
const PrivacyPage = React.lazy(() => import('../pages/PrivacyPage'));
const ContactUS = React.lazy(() => import('../pages/contactUS'));

export interface RouteItemProp {
  path: string;
  element: JSX.Element;
}

export const routeItems: RouteItemProp[] = [
    {
      path: "/",
      element: <LandingPage />
    },
    {
      path: "/pricing",
      element: <PricingPage />
    },
    {
      path: "/about",
      element: <AboutPage />
    },
    {
      path: "/joinwaitlist",
      element: <JoinWaitListPage />
    },
    {
      path: "/contact-us",
      element: <ContactUS/>
    },
    {
      path: "/rental-locations",
      element: <ServiceArea />
    },
    {
      path: "/resources",
      element: <Blog />
    },
    {
        path: "/resources/earthmoving",
        element: <BlogContent />
    },
    {
      path: "/terms-service",
      element: <TermsServicePage />
    },
    {
      path: "/privacy-policy",
      element: <PrivacyPage />
    },
    {
      path: "/usecases",
      element: <Promotion />
    },
];
