import { Input, Modal, Button } from 'antd';
import { TiLocationOutline } from 'react-icons/ti';
import React, { useState } from 'react';

type searchType = {
    bgcolor?: string;
    className?: string;
}

const LocationSearch = (props: searchType) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [location, setLocation] = useState('');
    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
        setLocation('Ukraine');
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    const yellowBtn = 'px-30 py-15 rounded-full bg-supernova text-darkGreen font-bold border hover:text-white hover:bg-yellowBrown';
    const inputBgColor = props.bgcolor ? props.bgcolor : 'bg-white';

    return (
        <div className={props.className}>
            <div className="flex items-center text-16 rounded-12 h-40 flex items-center">
                <TiLocationOutline className="cursor-pointer font-bold text-20 mr-10 text-black" onClick={showModal} />
                <input
                    placeholder="Set Location"
                    onChange={(e) => setLocation(e.target.value)}
                    value={location}
                    className={"outline-none text-black " + inputBgColor}
                />
            </div>
            <Modal 
                className="pinpoint-modal"
                title={[
                    <p className="text-20 font-bold mt-10 text-dune ">Let’s pinpoint your jobsite</p>,
                    <p className="text-14 mb-5 text-boulder ">Please provide the jobsite location to help us determine accurate<br /> rates and inventory in that area.</p>
                ]} 
                open={isModalOpen} 
                onCancel={handleCancel}
                footer={false}
            >
                <div className="flex items-center rounded-12 text-boulder bg-desertStorm py-15 px-20 text-14 mb-30">
                    <TiLocationOutline className="font-bold text-20" onClick={showModal} />
                    <input
                        id='input'
                        placeholder="Enter city, state or zip/postal code"
                        onChange={() => setLocation(location)}
                        value={location}
                        className="bg-desertStorm ml-10 outline-none"
                    />
                </div>
                <div className="flex flex-row-reverse">
                    <button type="submit" className={yellowBtn} onClick={handleOk}>Set Location</button>
                </div>
            </Modal>
        </div>
    )

}

export default LocationSearch;