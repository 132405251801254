import React, {useState, useEffect} from 'react';
import { useNavigate, useLocation  } from 'react-router-dom';
import { RiGlobalLine } from 'react-icons/ri';
import { BiHelpCircle, BiPhoneCall, BiMenu, BiChevronDown } from 'react-icons/bi';
import { useSelector, useDispatch } from 'react-redux';
import { MdOutlineClose } from 'react-icons/md';

import LocationSearch from '../components/AllProducts/locationSearch';
import { Dropdown, Space, Menu, Divider, Collapse } from 'antd';
import '../App.css';
import logoWhite from '../assets/images/logo-light1.png'
import logoBlack from '../assets/images/logo-light2.png'
import { NavLink } from 'react-router-dom';
import { menu_languages, menuProp, mainMenus } from '../core/constants/menu';
import { btnStyles, dFlexStyle, screenStyles } from '../core/constants/styles';
import { setHeaderTheme } from '../core/store/slices/settingSlice';
import { LinkButton } from '../components';

const { Panel } = Collapse;

const Header = (props: any) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const headerTheme = useSelector((state: any) => state.setting.headerTheme);

  const [background, setBackground] = useState('');
  const [showMMenu, setShowMMenu] = useState(false);

  const toggleMobileMenu = () => {
    if (!showMMenu) {
      setShowMMenu(true);
    }
    else {
      setShowMMenu(false);
    }
  };

  const onMenuClick = (e:any) => {
    const {key} = e;
    navigate(key);
  };

  useEffect(() => {
    const listenScrollEvent = (e:any) => {
      const fullUrl = window.location.href;
      const pathName = fullUrl.split('#')[1];
      const convertPos = pathName === '/' || !pathName ? 240 : 110;
      const headerStyle = pathName === '/' || !pathName ? 'white' : 'black';
      if (window.scrollY > convertPos) {
        setBackground(headerStyle === 'black' ? 'bg-darkGreen' : 'bg-white');
      } else {
        setBackground(headerStyle === 'black' ? 'bg-tranparent' : 'bg-white');
      }
    }

    const listenResizeWdith = (e:any) => {
      // if (window.innerWidth >= 1280 && showMMenu) {
        setShowMMenu(false);
      // }
    }
    window.addEventListener('scroll', listenScrollEvent);
    window.addEventListener('resize', listenResizeWdith);
  }, []);

  useEffect(() => {
    // if (location.pathname === '/' && headerTheme === 'black') {
      dispatch(setHeaderTheme('white'));
    // }
    // if (location.pathname !== '/' && headerTheme === 'white') {
    //   dispatch(setHeaderTheme('black'));
    // }
  }, [location]);

  const colorStyle = headerTheme === 'black' ? ' text-white ' : ' text-black ';
  const headerStyles = {
    headerPos: ' fixed top-0 left-0 w-full z-900' + (headerTheme === 'black' ? ' bg-headerGradient' : ' bg-white'),
    mobileMenu: 'fixed left-0 top-80 grid w-full bg-white p-24 z-10 font-medium text-16',
    header: 'flex justify-between items-center font-medium ' + screenStyles.mContain,
    mMenuItem: 'text-black py-10 border-b-1 border-softpeach ',
    navlinktop: colorStyle + 'mr-20 hover:text-supernova flex items-center',
    navlink: colorStyle + 'ml-20 hover:text-supernova',
    shotCart: colorStyle + 'hover:text-supernova',
  };

  return (
    <div className={colorStyle}>
      <div className={(showMMenu ? 'bg-white' : background) + headerStyles.headerPos}>
        <div className={screenStyles.desktop + " my-25 max-w-mxWidth mx-auto "}>
          <div className={headerStyles.header + ' mt-20 mb-10'}>
            <div className="flex items-center text-14">
              <RiGlobalLine className="mr-10" />
              <Dropdown overlay={
                <Menu
                  onClick={onMenuClick}
                  items={menu_languages}
                  style={{padding: '15px', borderRadius: '10px'}}
                />
              }>
                <a href='/' onClick={(e) => e.preventDefault()}>
                  <Space className={colorStyle}>
                    ENG
                    <BiChevronDown />
                    {/* <DownOutlined /> */}
                  </Space>
                </a>
              </Dropdown>
            </div>
            <div className="flex items-center text-14">
              <NavLink to="/contact-us" className={headerStyles.navlinktop}>Help<BiHelpCircle className="ml-5" /></NavLink>
              <NavLink to="/contact-us" className={headerStyles.navlinktop}>Contact Us<BiPhoneCall className="ml-5" /></NavLink>
            </div>
          </div>
          <Divider/>
          <div className={headerStyles.header + ' mt-20'}>
            <div className='display-menu-sapce flex items-center text-16'>
              <img src={headerTheme === 'white' ? logoBlack : logoWhite} className='App-logo' onClick={() => navigate('/home')} style={{ "pointerEvents": "all" }} alt='' />
              {mainMenus.map((menu: menuProp, index:number) => {
                if (menu.subItem) {
                  return (
                    <Dropdown overlay={
                        <Menu
                          onClick={onMenuClick}
                          items={menu.subItem}
                          style={{padding: '15px', borderRadius: '10px'}}
                        />
                      }
                      key={index}
                    >
                      <a href={menu.key} onClick={(e) => e.preventDefault()}>
                        <Space className={headerStyles.navlink}>
                          {menu.label}
                          <BiChevronDown />
                        </Space>
                      </a>
                    </Dropdown>
                  )
                } else
                  return (
                    <NavLink to={menu.key}
                      className={headerStyles.navlink}
                      key={index}
                    >
                      {menu.label}
                    </NavLink>
                  )
                })
              }
            </div>
            <div className="flex items-center">
              <LinkButton
                className="me-20"
                href="https://calendly.com/bigrentals/consultation"
                target="_blank"
              >
                Schedule Demo
              </LinkButton>
              <LinkButton
                type="supernova"
                href="https://calendly.com/bigrentals/consultation"
                target="_blank"
              >
                Free Consultation
              </LinkButton>
            </div>
          </div>
        </div>

        <div className={screenStyles.mobile + " w-full py-25 px-20"}>
          <div className={dFlexStyle + colorStyle}>
            <BiMenu size={25} onClick={toggleMobileMenu} className={showMMenu ? 'hidden' : '' } />
            <MdOutlineClose size={25} onClick={toggleMobileMenu} className={showMMenu ? '' : 'hidden' } />
            <img className='App-logo' src={headerTheme === 'white' ? logoBlack : logoWhite} alt='' onClick={() => navigate('/')} style={{ "pointerEvents": "all" }} />
            <div className='w-25'></div>
          </div>
        </div>
      </div>
      {showMMenu && (
        <div className={headerStyles.mobileMenu}>
          {mainMenus.map((menu: menuProp, index:number) => {
            if (menu.subItem) {
              return (
                <Collapse expandIconPosition='end' bordered={false} key={index} >
                  <Panel header={menu.label} key={index} className="text-16">
                    <div className="grid">
                      {
                        menu.subItem?.map((submenu: menuProp, sID:number) => {
                          return (
                            <NavLink to={submenu.key} className="py-10 text-black border-b-1 border-softpeach"
                            key={sID} onClick={() => setShowMMenu(false)}>
                              {submenu.label}
                            </NavLink>
                          )
                        })
                      }
                    </div>
                  </Panel>
                </Collapse>
              )
            } else
              return (
                <NavLink to={menu.key}
                  className={headerStyles.mMenuItem + ' border-t-1'}
                  onClick={() => setShowMMenu(false)}
                  key={index}
                >
                  {menu.label}
                </NavLink>
              )
            })
          }
          <LinkButton
            className="w-full h-40 my-10 hover:!bg-supernova border-1 border-supernova"
            href="https://calendly.com/bigrentals/consultation"
            target="_blank"
          >
            Free Consultation
          </LinkButton>
          <LinkButton
            className="w-full h-40 my-10 hover:!bg-supernova border-1 border-supernova"
            href="https://calendly.com/bigrentals/consultation"
            target="_blank"
          >
            Schedule Demo
          </LinkButton>

          {/* <a
            className={'w-full h-40 my-10 text-inherit ' + btnStyles.default}
            rel="noreferrer"
            href="https://calendly.com/bigrentals/consultation"
            target="_blank"
          >
            Free Consultation
          </a> */}
          {/* <LinkButton
            href="https://calendly.com/bigrentals/consultation"
            target='_blank'
          >
            Schedule Demo 1
          </LinkButton> */}
          {/* <a
            className={'w-full h-40 my-10 text-inherit ' + btnStyles.default}
            rel="noreferrer"
            href="https://calendly.com/bigrentals/consultation"
            target="_blank"
          >
            Schedule Demo
          </a> */}

          <LocationSearch className='my-10'/>

          <Divider />
          <div className="mt-10">
            {/* <RiGlobalLine style={{ marginRight: 10, color: 'black' }} size={18} /> */}
            <Collapse expandIconPosition='end' bordered={false}>
              <Panel header={
                <div className="flex items-center">
                  <RiGlobalLine className="text-20 font-bold"/>
                  <span className="ml-10">English</span>
                </div>}
                key="1"
              >
                <div style={{ display: 'grid' }}>
                  <NavLink to="/" style={{ padding: 10 }}>Japonese</NavLink>
                  <Divider />
                  <NavLink to="/" style={{ padding: 10 }}>Chinese</NavLink>
                </div>
              </Panel>
            </Collapse>
          </div>
        </div>
      )}
    </div>

  )
}

export default Header;
