
export const btnStyles = {
    default: 'bg-transparent rounded-full text-center font-bold cursor-pointer hover:bg-supernova text-black hover:text-black border-1 border-supernova',
    active: 'bg-supernova rounded-full text-center font-bold cursor-pointer hover:bg-transparent hover:text-black border-1 border-supernova',
    outline: 'bg-transparent rounded-full text-center text-white font-bold cursor-pointer hover:bg-supernova hover:text-black border-1 border-supernova',
    addCart: 'w-full md:w-130 h-50 rounded-full bg-supernova text-darkGreen font-bold border hover:text-white hover:bg-yellowBrown',
    calcBtn: 'w-full h-50 rounded-full bg-supernova text-darkGreen font-bold border hover:text-white hover:bg-yellowBrown',
    yellow: 'rounded-full bg-supernova text-darkGreen font-bold border hover:text-white hover:bg-yellowBrown',
    tryMemberShip: 'bg-supernova rounded-full text-center font-bold cursor-pointer text-darkGreen hover:text-white border-1 border-pinocchio'
}

export const screenStyles = {
    desktop: ' hidden lg:block ',
    mobile: ' block lg:hidden ',
    mdDesktop: 'hidden md:block ',
    mdMobile: 'block md:hidden ',
    smDesktop: 'hidden sm:block ',
    smMobile: 'block sm:hidden ',
    mContain: ' mx-25 sm:mx-40 md:mx-60 lg:mx-80 '
}

export const dividerStyles = {
    horizontal: ' w-full h-[1px] my-10 md:my-20 bg-softpeach ',
    vertical: ' w-[1px] mx-10 md:mx-20 bg-softpeach ',
    defaultH: ' w-full h-[1px] bg-softpeach ',
    defaultV: ' w-[1px] bg-softpeach '
}

export const titleStyles = {
    default: screenStyles.mContain + ' text-40 font-bold text-white text-center lg:text-left leading-[120%]'
}

export const headerStyle = 'text-30 sm:text-40 font-bold text-center md:text-left';

export const dFlexStyle = ' flex justify-between items-center ';

export const badgeStyles = {
    discount: 'text-greenish bg-aquaSpring border-1 border-aquaSpring rounded-10 px-8 py-4 text-16 leading-24 font-medium mx-10 ',
    prefer: 'text-azure bg-water border-1 border-water rounded-10 px-8 py-4 text-16 leading-24 mx-10 ',
}