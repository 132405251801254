import Header from './Header';
import Footer from './Footer';
import WatchDemoModal from '../components/WatchDemoModal';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { setWatchDemoModal, setWaitListModal} from '../core/store/slices/settingSlice';
import JoinWaitListPage from '../pages/WaitListPage';
import '../core/lib/chat/chatModule.js';

const NormalLayout = (props: { children: any }) => {
  const dispatch = useDispatch();
  const demoModal = useSelector((state: any) => state.setting.watchDemoModal);
  const waitListModal = useSelector(
    (state: any) => state.setting.waitListModal
  );

  return (
    <div className="flex flex-col min-h-screen overflow-hidden font bg-white text-14 ">
      <Header />
      {props.children}
      <Footer />
      <WatchDemoModal open={demoModal} handleClose={() => dispatch(setWatchDemoModal(false))} />
    </div>
  );
};

export default NormalLayout;
