import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  headerTheme: 'black',
  watchDemoModal: false,
  waitListModal: false
};

export const settingSlice = createSlice({
  name: 'setting',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setHeaderTheme: (state, { payload }) => {
      state.headerTheme = payload;
    },
    setWatchDemoModal: (state, { payload }) => {
      state.watchDemoModal = payload;
    },
    setWaitListModal: (state, { payload }) => {
      state.waitListModal = payload;
    },
  }
});

export const { 
  setHeaderTheme,
  setWatchDemoModal,
  setWaitListModal
} = settingSlice.actions;
export default settingSlice.reducer;
