 import { AiOutlinePlayCircle } from 'react-icons/ai';
import { screenStyles } from '../core/constants/styles';
import { useSelector } from 'react-redux';
import LinkButton from '../components/LinkButton';

const AlreadyKnow = () => {
  const headerTheme = useSelector((state: any) => state.setting.headerTheme);
  const bgColor = headerTheme === 'white' ? ' bg-vistaWhite ' : ' bg-dune ';
  const fontColor = headerTheme === 'white' ? ' text-black ' : ' text-lemonGrass ';
  const boxStyle = screenStyles.mContain + bgColor + " p-15 py-25 sm:p-50 rounded-16 flex flex-wrap justify-center md:justify-between items-center";

  return (
    <section className={'max-w-mxWidth mx-auto w-full relative lg:pt-80' + fontColor}>
      <div className={boxStyle}>
        <div>
          <h2 className="text-30 sm:text-40 m-0 font-bold">
            Try Big Rentals for free
          </h2>
          <p className='text-20 mb-0 mt-16'>Take the consultation and see the benefits of rental software.</p>
        </div>
        <div className={screenStyles.smDesktop + ' sm:flex my-10 '}>
          <LinkButton
            className="m-10 py-20 hover:!bg-supernova"
            href="https://calendly.com/bigrentals/consultation"
            target="_blank"
          >
            <AiOutlinePlayCircle className="mr-5" />
            Schedule Demo
          </LinkButton>
          <LinkButton
            className="m-10 py-20 !text-black"
            type="supernova"
            href="https://calendly.com/bigrentals/consultation"
            target="_blank"
          >
            Free Consultation
          </LinkButton>
        </div>
        <div className={screenStyles.smMobile + ' flex my-10'}>
          <LinkButton
            className="m-10 py-20 hover:!bg-supernova"
            href="https://calendly.com/bigrentals/consultation"
            target="_blank"
          >
            <AiOutlinePlayCircle className="mr-5" />
            Schedule Demo
          </LinkButton>
          <LinkButton
            className="m-10 py-20 !text-black"
            type="supernova"
            href="https://calendly.com/bigrentals/consultation"
            target="_blank"
          >
            Free Consultation
          </LinkButton>
        </div>
      </div>
    </section>
  )
}

export default AlreadyKnow;
