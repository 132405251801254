import { chooseIndustryProp } from "../interfaces/base";

export interface menuProp {
  label: string;
  key: string;
  submenu?: true | false;
  subItem?: menuProp[];
}

export const menu_languages:menuProp[] = [
  {
    label: 'ENG',
    key: '1',
  },
  {
    label: 'JAP',
    key: '2',
  },
  {
    label: 'CHE',
    key: '3',
  },
];
export const menu_equipments:menuProp[] = [
  {
    label: 'All Equipment',
    key: '/product',
  },
  {
    label: 'Material Handling',
    key: '2',
  },
  {
    label: 'Lifts and Aerial',
    key: '3',
  },
  {
    label: 'Trailers',
    key: '4',
  },
  {
    label: 'Site Services',
    key: '5',
  },
  {
    label: 'Compact Equipment',
    key: '6',
  },
  {
    label: 'Heavy Earthmoving',
    key: '7',
  }
];

export const menu_resources:menuProp[] = [
  {
    label: 'All Resources',
    key: '/resources',
  },
  {
    label: 'Earth Moving',
    key: '/resources/earthmoving',
  },
];
export const menu_usecases:menuProp[] = [
  {
    label: 'All Usecases',
    key: '/usecases',
  },
  {
    label: 'Use Case1',
    key: '/usecases',
  },
];

export const mainMenus: menuProp[] = [
  /*{
    label: 'Product',
    key: '/product',
    subItem: menu_equipments
  },
  {
    label: 'Use Cases',
    key: '/usecases',
    subItem: menu_usecases
  },
  {
    label: 'Pricing',
    key: '/pricing'
  },
  {
    label: 'Partners',
    key: '/partners'
  },
  {
    label: 'Resources',
    key: '/resources',
    subItem: menu_resources
  },*/
];

export const choose_industries: chooseIndustryProp[] = [
  {
    title: 'Agriculture',
    url: ''
  },
  {
    title: 'Engineering',
    url: ''
  },
  {
    title: 'General Contractor',
    url: ''
  },
  {
    title: 'IT Services',
    url: ''
  },
  {
    title: 'Plumbing',
    url: ''
  },
  {
    title: 'Roofing and Wall Covering',
    url: ''
  },
  {
    title: 'Electrical Contracting',
    url: ''
  },
  {
    title: 'Facilities Maintenance',
    url: ''
  },
  {
    title: 'Government Solutions',
    url: ''
  },
  {
    title: 'Media and Entertainment',
    url: ''
  },
  {
    title: 'Retail Construction',
    url: ''
  },
  {
    title: 'Telecommunications',
    url: ''
  }
];