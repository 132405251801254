import { Helmet } from 'react-helmet';

export interface IProps {
  /// should optimally have length <= 60 characters
  title?: string

  /// should optimally have 150 <= length <= 160 characters
  description?: string

  /// can be relative or absolute or will use a fallback image if not set
  imageUrl?: string

  keywords?: string
}

function NoRobotMetaTags() {
  return (
    <Helmet>
      <meta name="robots" content="noindex" />
    </Helmet>
  );
}

// apply some branding addendum automatically if not deactivated
function TitleMetaTags({ title }: IProps) {
  const finalTitle = title;
  return (
    <Helmet>
      <title>{finalTitle}</title>
      <meta property="og:title" content={title} />
      <meta property="twitter:title" content={title} />
    </Helmet>
  );
}

// use the given description as-is
function DescriptionMetaTags({ description }: IProps) {
  return (
    <Helmet>
      <meta name="description" content={description} />
      <meta property="og:description" content={description} />
      <meta property="twitter:description" content={description} />
    </Helmet>
  );
}

// use the given keywords as-is
function KeywordMetaTags({ keywords }: IProps) {
  return (
    <Helmet>
      <meta name="keywords" content={keywords} />
      <meta property="og:keywords" content={keywords} />
      <meta property="twitter:keywords" content={keywords} />
    </Helmet>
  );
}

export function MetaTags(props: IProps) {
  const { title, description, keywords } = props;
  return (
    <>
      {(undefined !== process.env.REACT_APP_ENV && process.env.REACT_APP_ENV === 'dev1') ? (
        <NoRobotMetaTags />
      ):(
        <>
          <TitleMetaTags title={title} />
          <DescriptionMetaTags description={description} />
          <KeywordMetaTags keywords={keywords} />
        </>
      )}
    </>
  );
}
