import React from 'react'
import { Row, Col, Divider } from 'antd'
import { NavLink, useLocation } from 'react-router-dom';
import { HiOutlineMail } from 'react-icons/hi'
import { BsTelephone } from 'react-icons/bs'
import { MdOutlineLocationOn } from 'react-icons/md'
import logoWhite from '../assets/images/logo-light1.png'
import logoBlack from '../assets/images/logo-light2.png'
import facebook from '../assets/images/facebook.png'
import twitter from '../assets/images/twitter.png'
import linkedin from '../assets/images/linkedin.png'
import instagram from '../assets/images/instagram.png'
import { screenStyles } from '../core/constants/styles'
import AlreadyKnow from './alreadyKnow';
import { useSelector } from 'react-redux';
const Footer = () => {
  const location = useLocation();
  const headerTheme = useSelector((state: any) => state.setting.headerTheme);
  let layoutFlag = true;
  if (location.pathname === '/contact-us') layoutFlag = false;
  if (location.pathname === '/about') layoutFlag = false;
  if (location.pathname === '/shoppingcart') layoutFlag = false;
  if (location.pathname === '/checkout') layoutFlag = false;
  if (location.pathname === '/orderconfirmation') layoutFlag = false;
  const footerColor = headerTheme === 'white' ? ' bg-white ' : ' bg-darkGreen ';
  const fontColor = headerTheme === 'white' ? ' text-black ' : ' text-lemonGrass ';
  const footerStyles = {
    navigateHeader: fontColor + " text-18 font-bold",

  }

  return (
    <footer id='test' className={footerColor + fontColor + " py-30 " + (layoutFlag ? '' : 'mt-120 ')}>
      {layoutFlag && (
        <AlreadyKnow />
      )}
      <section className="max-w-mxWidth mx-auto">
        <div className={" text-16 " + (layoutFlag ? 'pt-80 lg:pt-120 ' : '') + screenStyles.mContain}>
          <Row className='my-20'>
            <Col xs={24} sm={24} md={9} className="my-20">
              <div className="mx-20">
                <img className='App-logo' src={headerTheme === 'white' ? logoBlack : logoWhite} alt='' />
                <p className="my-16">Big equipment rentals made easy.</p>
                <div className="flex">
                  <img src={facebook} className="mr-10" alt='' />
                  <img src={twitter} className="mr-10" alt='' />
                  <img src={linkedin} className="mr-10" alt='' />
                  <img src={instagram} className="mr-10" alt='' />
                </div>
              </div>
            </Col>
            <Col xs={24} sm={8} md={5} className="my-20">
              {/*<div className="mx-20">
                <p className={footerStyles.navigateHeader}>Navigations</p>
                <p><NavLink to="/product" className={fontColor}>Reservations</NavLink></p>
                <p><NavLink to="/product" className={fontColor}>Locations</NavLink></p>
                <p><NavLink to="/rental-locations" className={fontColor}>Questions</NavLink></p>
              </div>*/}
            </Col>
            <Col xs={24} sm={8} md={5} className="my-20">
              {/*<div className="mx-20">
                <p className={footerStyles.navigateHeader}>Resources</p>
                <p><NavLink to="/product" className={fontColor}>Rentals Guide</NavLink></p>
                <p><NavLink to="/product" className={fontColor}>Equipment Manuals</NavLink></p>
                <p><NavLink to="/product" className={fontColor}>Case Studies</NavLink></p>
              </div>*/}
            </Col>
            <Col xs={24} sm={8} md={5} className="my-20">
              <address className="mx-20">
                <p><NavLink to="/contact-us" className={footerStyles.navigateHeader}>Contact Us</NavLink></p>
                <a className='flex items-center text-inherit mb-16' href="mailto:support@bigrentals.com">
                  <HiOutlineMail className="mr-10" />support@bigrentals.com
                </a>
                <a className='flex items-center text-inherit mb-16' href='tel:650646'>
                  <BsTelephone className="mr-10" />(650) 646-RENT
                </a>
                {/* <p className='flex items-center'><BsTelephone className="mr-10" /> (310) 919-2104</p> */}
                <p className='flex items-center'><MdOutlineLocationOn className="mr-10 text-20" />Los Angeles, CA</p>
              </address>
            </Col>
            <Divider style={{ margin: '20px 0' }} />

          </Row>
          <div className="block justify-between sm:flex font-medium">
            <p className="mb-20">
              Copyright 2024 Big Rentals Inc.
            </p>

            <div className="block sm:flex">
              {/*<p className="mr-24 my-16"><NavLink to="/privacy-policy" className={fontColor} >Privacy Policy</NavLink></p>
              <p className="mr-24 my-16"><NavLink to="/terms-service" className={fontColor} >Terms of Service</NavLink></p>
              <p className="mr-24 my-16"><NavLink to="/rental-locations" className={fontColor} >Sitemap</NavLink></p>*/}
              <p className="mr-24 my-16"><NavLink to="https://marketplace.bigrentals.com/privacy-policy" className={fontColor} >Privacy Policy</NavLink></p>
              <p className="mr-24 my-16"><NavLink to="https://marketplace.bigrentals.com/terms-service" className={fontColor} >Terms of Service</NavLink></p>
              <div className='mr-24 my-16 link-disabled'>Sitemap</div>
            </div>

          </div>
        </div>
      </section>
    </footer>
  )
}
export default Footer;