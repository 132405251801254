import { Suspense } from "react";
import "./index.css";
import "antd/dist/antd.css";
import { HashRouter, Navigate, Route, Routes } from "react-router-dom";
import { AuthProvider } from "./core/context/AuthContext";
import './App.css';
import { MetaTags } from './layouts/metaSetting';
import loadingImg from "./assets/images/loading.png";

import NormalLayout from './layouts/normal';
import { routeItems, RouteItemProp } from "./core/routes";

export const App = () => {
  const title = 'Big Rentals | Discover the Best Trailer Rental Marketplace';
  const description = 'Grow sales, accept online bookings, and streamline fleet & rental operations with a powerful rental management software. We make it easy to rent out trailers, dumpsters, and equipment with a professional web store and booking system';
  const keywords = 'big rentals, bigrentals.com, equipment rental software, online equipment booking software, online rental store, all-in-one equipment rental software, equipment inspection software, equipment rental scheduling online, trailer rental software, equipment rental software, dumpster rental software, online rental business software';

  return (
    <Suspense fallback={(
      <div className="absolute top-0 bottom-0 left-0 right-0 flex items-center justify-center z-10 bg-[#FFFFFFB3]">
        <img src={loadingImg} alt='loading...' width={200} height={200} />
      </div>
    )}>
      <HashRouter>
        <NormalLayout>
          <MetaTags title={title} description={description} keywords={keywords} />
          <AuthProvider>
            <Routes>
            {
              routeItems.map((item:RouteItemProp, index:number) => {
                return (
                  <Route path={item.path} element={item.element} key={index}/>
                )
              })
            }
              <Route path='*' element={<Navigate to='/' />} />
            </Routes>
          </AuthProvider>
        </NormalLayout>
      </HashRouter>
    </Suspense>
  );
}