import { Modal } from "antd";
import videoImg from "../assets/images/blogcontent.png";

type WatchDemoModalType = {
  open: boolean;
  handleClose: any;
};

const WatchDemoModal = (props: WatchDemoModalType) => {
  return (
    <Modal 
      className="pinpoint-modal watch-video-modal"
      // title={[
      //   <p className="text-14 mb-5 text-boulder ">Demo video</p>
      // ]}
      // centered
      open={props.open} 
      onCancel={props.handleClose}
      footer={false}
      closable={false}
    >
      <img src={videoImg} style={{width: '100%', height: '100%'}}/>
    </Modal>
  )
}

export default WatchDemoModal;